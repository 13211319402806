import React from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';


function Article() {
  const { id } = useParams();
  
  return (
   <div className='main-content'>
    <Container>
    
      <h1>Article {id}</h1>
      <p>This is the content for Article {id}. You can replace this with the actual article content.</p>
     
    </Container>
    </div> 
  );
}

export default Article;
