import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './pages/Header';
import Home from './pages/Home';
import Technology from './industries/Technology'
import Article from './pages/Article'; 
import Footer from './pages/footer';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/technology" element={<Technology/>} />
        <Route path="/article/:id" element={<Article />} /> {/* Placeholder for articles */}
        <Route path="/featured-insights" element={<div>Insights Page</div>} /> {/* Placeholder */}
      </Routes>
     <Footer/>
    </Router>
  );
}

export default App;
