import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import './Header.css'; // Import the custom CSS file
import logo from '../assets/logo.jpg';
import { Link } from 'react-router-dom'; 

function Header() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Toggle sidebar open/close state
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      {/* Sidebar */}
      <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
        <button className="close-btn" onClick={toggleSidebar}>
          &times; {/* X symbol */}
        </button>
        <Nav className="flex-column">
          <NavDropdown title="Industries" id="industries-dropdown">
            <NavDropdown.Item href="/industries/finance">Finance</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/technology">Technology</NavDropdown.Item> 
          </NavDropdown>

          <NavDropdown title="Capabilities" id="capabilities-dropdown">
            <NavDropdown.Item href="/capabilities/strategy">Strategy</NavDropdown.Item>
            <NavDropdown.Item href="/capabilities/digital">Digital</NavDropdown.Item>
          </NavDropdown>

          <Nav.Link href="/featured-insights">Featured Insights</Nav.Link>
          <Nav.Link href="/careers">Careers</Nav.Link>
          <Nav.Link href="/about-us">About Us</Nav.Link>
        </Nav>
      </div>

      {/* Navbar */}
      <Navbar expand="lg" className="custom-navbar" sticky="top">
        <Container>
          {/* Hamburger Menu on the left */}
          <button className="navbar-toggler" onClick={toggleSidebar} aria-controls="responsive-navbar-nav" aria-expanded={sidebarOpen}>
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Brand Logo */}
          <Navbar.Brand href="/" className="mx-auto">
            <img 
              src={logo} // Replace with your logo path
              alt="Renbsha Logo"
              className="logo" // Add a class for styling
            />
          </Navbar.Brand>

          {/* Full Navigation Menu */}
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <NavDropdown title="Industries" id="industries-dropdown">
                <NavDropdown.Item href="/industries/finance">Finance</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/technology">Technology</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Capabilities" id="capabilities-dropdown">
                <NavDropdown.Item href="/capabilities/strategy">Strategy</NavDropdown.Item>
                <NavDropdown.Item href="/capabilities/digital">Digital</NavDropdown.Item>
              </NavDropdown>

              <Nav.Link href="/featured-insights">Featured Insights</Nav.Link>
              <Nav.Link href="/careers">Careers</Nav.Link>
              <Nav.Link href="/about-us">About Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
