import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa'; // Ensure this import is correct
import './Cards.css'; // CSS for the Cards component

const articles = [
  {
    id: 1,
    title: 'Article Title 1',
    text: 'Short description of Article 1.',
    imageUrl: 'https://img.freepik.com/premium-photo/papercut-childhood-dreams-toys-imaginary-friends-joyful-2d-layered-layout_720722-27876.jpg?w=1060', // Add your image path here
    link: '/article/1',
  },
  {
    id: 2,
    title: 'Article Title 2',
    text: 'Short description of Article 2.',
    imageUrl: 'https://images.unsplash.com/photo-1600675608140-991fcf38cc6e?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA==', // Add your image path here
    link: '/article/2',
  },
  {
    id: 3,
    title: 'Article Title 3',
    text: 'Short description of Article 3.',
    imageUrl: 'https://img-c.udemycdn.com/course/750x422/4316500_c1ab_2.jpg', // Add your image path here
    link: '/article/3',
  },
  // Add more articles as needed
];

function Cards() {
  return (
    <div className="cards-section">
       
      <Row xs={1} md={2} lg={3} className="g-4">
        {articles.map((article) => (
          <Col key={article.id}>
            <Card className="h-100">
              <Card.Img variant="top" src={article.imageUrl} alt={article.title} />
              <Card.Body>
                <Card.Title>{article.title}</Card.Title>
                <Card.Text>{article.text}</Card.Text>
                <a href={article.link} className="learn-more-link">
                  Learn More <FaArrowRight />
                </a>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default Cards;
